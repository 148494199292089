<template>
  <div class="info-page">
    <van-nav-bar title="网关数据" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="glo-content">
        <div>
            <div v-if="loading">
                <van-loading type="spinner" vertical>加载中</van-loading>
            </div>
            <div v-else>
                <van-collapse v-model="activeNames" accordion>
                    <van-collapse-item v-for="(item,index) in gData" :key="index" :title="item.modName">
                        <van-cell title="设备编号" :value="item.devId || '暂无数据'" />
                        <van-cell title="设备类型" :value="item.devType == 'GW'?'网关模块':item.devType" />
                        <van-cell title="设备时间" :value="item.devTime" />
                        <van-cell title="充电电压" :value="(item.envVoltage / 1000).toFixed(2)+' V'" />
                        <van-cell title="电池电压" :value="(item.batVoltage / 1000).toFixed(2)+' V'" />
                        <van-cell title="设备温度" :value="(item.devTemp || 0)+' ℃'" />
                        <van-cell title="4G信号强度" :value="item.rssi4g" />
                        <van-cell title="Wifi信号强度" :value="item.wifirssi" />
                        <van-cell title="GPS卫星数量" :value="item.satellite" />
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        activeNames:0,
        bridgeCode:'',
        loading:true,
        gData:[]
    }
  },
  methods:{
      onClickLeft(){
        this.$router.go(-1);//返回上一层
      },
      // 获取设备传感器信息
      getData(){
          this.$api.SLOPE.geteWayData({
              bridgeCode:this.bridgeCode
          }).then( d => {
              this.gData = d;
              this.loading = false;
          })
      }
  },
  mounted(){
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
      this.bridgeCode = '510321003';
      this.bridgeCode ? this.getData() : void 0;
  },
  beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
}
}
</script>
<style lang="scss" scoped>
.van-cell__title{text-align: left;}
.van-cell__value {
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;
    display: inherit;
}
.van-loading--vertical {
    margin: 50% auto;
}
</style>